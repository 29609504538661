 
.spinner-container {
  display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    height: 100vh;
}
 .loading-spinner{
  text-align: center;
  width: 100%;
 }

 .spinner-container-table {
  display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
}
