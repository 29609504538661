/* PRELOADER CSS
.page-loader{
	width: 100%;
	height: 100vh;
	position: absolute;
	z-index: 1000;
  background: #fff;
  text-align: center;
} */

.nav-link.active {
  color: #f6a21e !important;
}

.navheader {
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 1020;
  padding: 3px;
  border-bottom: 1px solid #dee2e6 !important;
}

.navlink li a {
  color: #000;
  font-weight: 500;
}

.navlink li a:hover {
  color: #134d75;
  font-weight: 500;
}

.navlink li a:active {
  color: #f6a21e !important;
  font-weight: 500;
}

.menbtn {
  border: 1px solid #134d75;
  border-radius: 50px;
  padding: 7px 15px;
  color: #fff;
  background: #134d75;
  font-size: 16px;
}

.menbtn:hover,
.menbtn:active {
  color: #fff !important;
  border: 1px solid #f6a21e;
  border-radius: 50px;
  padding: 7px 15px;
  background: #f6a21e !important;
  font-size: 16px;
}

.fa-user:before {
  font-size: 20px;
}

#navmenu li {
  padding: 0 16px;
}

.userdropdown {
  top: 8px;
  position: relative;
}

.dropdownicon {
  padding: 0;
}

.dropdownicon::after {
  -webkit-text-fill-color: transparent;
  align-items: center;
  background: -webkit-linear-gradient(#255a80, #86d0db);
  -webkit-background-clip: text;
  border: none;
  content: "\f107";
  display: flex;
  font-family: Font Awesome\ 5 Free;
  font-size: 16px;
  font-weight: 900;
  position: absolute;
  right: 10px;
  top: -10px;
  bottom: 0;
  align-content: center;
  flex-wrap: wrap;
}

.logintbtn {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
}

.logintbtn:hover {
  background: #134d75;
  padding: 10px 15px;
  border: none;
  color: #fff !important;
  border-radius: 4px;
}

.navbar-nav .dropdown-menu li a:hover {
  background: #134d75;
}

.droplogin {
  color: #000 !important;
  margin-top: -2px;
}

.droplogin1 li {
  padding: 0 !important;
}

.droplogin1 li:hover {
  background: #134d75;
  color: #fff !important;
  padding: 0 !important;
}

.droplogin1 li:active {
  background: #134d75;
  color: #fff !important;
  padding: 0 !important;
}

.droplogin1 li a:active {
  background: #134d75;
  color: #fff !important;
}

.droplogin1a {
  color: #000 !important;
  text-decoration: none;
}

.droplogin1a:hover {
  color: #fff !important;
  text-decoration: none;
}

.navbar-toggle {
  display: none !important;
}

/*Navbar toggle close*/
.navbar-toggler {
  border-color: transparent;
  padding: 0;
  background: none;
  position: relative;
  width: 30px;
  height: 30px;
}

.navbar-toggler span {
  display: block;
  width: 100%;
  height: 3px;
  background: -webkit-linear-gradient(#134d75, #007a9f);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: transform 0.3s;
}

.navbar-toggler span:nth-child(1) {
  transform: translateY(-9px);
}

.navbar-toggler span:nth-child(3) {
  transform: translateY(7px);
}

.navbar-toggler[aria-expanded="true"] span:nth-child(1),
.navbar-toggler[aria-expanded="true"] span:nth-child(3) {
  transform: rotate(45deg);
}

.navbar-toggler[aria-expanded="true"] span:nth-child(2) {
  opacity: 0;
}

.navbar-toggler[aria-expanded="true"] span:nth-child(1) {
  transform: translateY(0) rotate(45deg);
}

.navbar-toggler[aria-expanded="true"] span:nth-child(3) {
  transform: translateY(0) rotate(-45deg);
}

.signupsvg {
  fill: #f6a21e;
}

/*Banner Section*/
.bannersection {
  background: #fff;
  background-image: url(../images/backbanner.png);
  background-position: top;
  padding: 80px 0;
  background-size: cover;
}

#banner {
  align-items: center;
}

.banner {
  display: flex;
  align-items: flex-end;
}

.banner-text h1 {
  font-size: 2rem;
  line-height: 1;
  margin-bottom: 20px;
  line-height: 50px;
  font-weight: 600;
}

.banner-text1 h1 {
  font-size: 3rem;
  line-height: 1;
  margin-bottom: 20px;
  line-height: 50px;
  color: #134d75;
  font-weight: 600;
  text-align: center;
}

.banner-text p {
  color: #666;
  line-height: 1.5 !important;
  font-size: 18px !important;
  text-align: justify;
  word-spacing: -1px;
}

.landbtn {
  background: #134d75;
  color: #fff !important;
  border-radius: 0.25rem;
  padding: 7px 15px;
}

.landbtn:hover,
.landbtn:active {
  background: #f6a21e !important;
  color: #fff !important;
  border-radius: 0.25rem;
  padding: 7px 15px;
}

/*Mid Section*/
.midsection {
  padding: 80px 0;
  background: #fff;
}

.infocard {
  background: #fff;
  text-align: center;
  border-radius: 5px;
  height: 100%;
}

.img-infocard {
  margin: auto;
  padding: 10px;
}

.text-infocard {
  font-size: 14px;
}

.text-infocard h5 {
  font-weight: 600;
}

.text-infocard p {
  color: #fff;
  text-align: center;
}

.midhead h2 {
  text-align: center;
  color: #134d75;
  font-weight: 600;
}

.midhead h2 {
  text-align: center;
}


/*flip*/

.flip-card-back p span {
  display: block;
}

.flip-card {
  background-color: transparent;
  height: 300px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 9px;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px 1px;
  border-radius: 9px;
}

.flip-card-front {
  color: black;
  background: #fff;
}

.whyhead {
  padding-top: 20px;
}

.flip-card-back {
  background-color: #134d75;
  color: var(--white);
  transform: rotateY(180deg);
  display: flex;
  justify-content: center;
}

.flip-card-back p {
  overflow: hidden;
  padding: 10px 20px;
  padding-bottom: 0;
  -webkit-box-orient: vertical;
}


/*How it works*/
.howitwork {
  padding: 80px 0;
  background: #f1fafd;
}

.its_card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 1rem;
}

.its-body-txt p {
  color: #666;
}

.firstheader {
  padding-top: 3rem;
}

.its-body-txt li {
  line-height: 1.2 !important;
  font-size: 18px !important;
  color: #666;
  list-style: none;
}

.check {
  background-image: url(../images/check.png);
  background-repeat: no-repeat;
  height: 34px;
  display: flex;
  align-items: center;
  padding-left: 2.5rem;
}

.check1 {
  background-image: url(../images/check.png);
  background-repeat: no-repeat;
  height: 34px;
  display: flex;
  align-items: center;
  padding-left: 2.5rem;
  margin-top: 1rem;
}

/*Tab Section*/

.tabsection {
  padding: 80px 0;
  background: white
}

.text-muted {
  text-align: justify;
}

.nav-pills-custom .nav-link {
  color: #fff;
  font-weight: 900;
  background: #134d75;
  position: relative;
}

.nav-pills-custom .nav-link.active {
  color: #134d75 !important;
  background: white;
}

.midhead p {
  line-height: 1.5 !important;
  font-size: 18px !important;
  color: #232323;
  text-align: justify;
}

/* Add indicator arrow for the active tab */
.nav-pills-custom .nav-link.active::before {
  opacity: 1;
}

.tab-nav-link:active {
  color: #134d75 !important;
}

.tab_accordian_responsive {
  border: none;
}

.faqaccordian {
  --bs-accordion-border-radius: 0px;
  --bs-accordion-inner-border-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.accordion-button:not(.collapsed) {
  background: #fff;
  color: #134d75;
}


.accordion-body {
  padding-left: 0;
  padding-right: 0;
}

.acordiancolor {
  color: #fff;
  background: #134d75;
  font-weight: 800;
}

.acordiancolor::after {
  display: none !important;
}

/*Feature Section*/

.featuresection {
  padding: 80px 0;
  background: #f1fafd;
}

.tab_lineheight {
  padding: 1.5rem;
  height: 95%;
}

/* .tab-content>.active{
  padding: 1.5rem;
} */
.separator {
  color: #134d75;
  margin: 0 auto 20px;
  max-width: 240px;
  text-align: center;
  position: relative;
}

.separator::before {
  float: left;
}

.separator:after {
  float: right;
}

.separator:before,
.separator:after {
  display: block;
  width: 40%;
  content: " ";
  margin-top: 10px;
  border: 1px solid #134d75;
}

.featuretext {
  padding: 5px 0;
}

.featuretextheade {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.arrow-right {
  background: #134d75;
  border-radius: 50%;
  padding: 14px 18px;
  color: #fff;
  margin-right: 14px;
}

.headfeature {
  font-weight: 600;
}

.parafeature {
  margin-left: 3.2rem;
  text-align: justify;
}

.feactureimg {
  padding: 10px;
  text-align: center;
}

.featurespacing {
  margin-top: 4rem;
}

.checkboximg {
  margin-right: 1rem;
}

/* Testimonial */
.testimonial1 {
  padding: 80px 0;
  background: white;
}

.testingbox:before {
  font-family: Revalia;
  color: #696773;
  font-size: 100px;
  position: absolute;
}

.testingbox:before {
  content: '“';
  top: -40px;
  left: 20px;
  padding: 20px;
}

.testingbox {
  padding: 20px;
  /* background: #ffffffa3; */
  position: relative;
}

.testimonial-wrapper {
  margin-top: 8rem;
}

.testimonial-bubble {
  position: relative;
  background-color: #134d75;
  border-radius: 20px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 0 10px 0 #fff;
  color: #e3ffe7;
  background-color: #134d75;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 #fff;
  color: #e3ffe7;
  height: 100%;
  min-height: 260px;
  padding-bottom: 10px;
}

.testimonial-bubble:before {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  border-style: solid;
  border-width: 20px 20px 0 20px;
  border-color: #134d75 transparent transparent transparent;

}

.testimonial-bubble h3 {
  margin: 0;
}

.testimonial-bubble h4 {
  font-size: 20px;
  padding-top: 5px;
}

.testimonial-bubble p {
  margin: 0px;
}

.testimonial-text p {
  margin-top: 20px;
}

.testimonial-rating {
  font-size: 12px;
  color: #fff;
  padding: 5px;
}

.checked {
  color: #f6a21e;
}

.testimonial-author {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: -6rem;
  left: 0;
}

.testimonial-author img {
  border-radius: 50%;
  margin-bottom: 10px;
  width: 120px;
  height: 120px;
  box-shadow: rgb(14 30 37 / 19%) 0px 1px 7px 0px, rgb(14 30 37 / 8%) 0px 0px 17px 0px;
}

.testimonial-author h3 {
  font-size: 16px;
}

.testimonial-author p {
  font-size: 14px;
  color: #999;
  margin-bottom: 0;
}


.testingbox:before {
  font-family: Revalia;
  color: #f6a21e;
  font-size: 100px;
  position: absolute;
  display: none;
}

.testingbox:before {
  content: '“';
  top: -40px;
  left: 20px;
  padding: 20px;
}

.testingbox {
  padding: 20px;
  position: relative;
}

.carousel-control-prev-icon {
  background-image: url(../images/right-left.png);
}

.carousel-control-next-icon {
  background-image: url(../images/right-arrow.png);
}

.carousel-control-next,
.carousel-control-prev {
  width: 3%;
  opacity: 0.8;
}

.blog_spacing {
  padding: 0 1rem;
}

.viewallblogbtn {
  margin: 0 0.8rem;
  padding-top: 2rem !important;
}

.blogcontent {
  line-height: 26px;
  color: #134d75;
  font-size: 18px;
  font-weight: 600;
}

/*Blog Page*/
#blog {
  padding: 80px 0;
  background: #f1fafd;
}

.blog_link {
  text-decoration: none;
  color: #212529;
  cursor: pointer;
}

.blog img {
  object-fit: cover;
}

.card__header {
  margin: auto;
}

.card__header img {
  height: 250px;
  margin-top: 10px;
}

.bloglink {
  text-decoration: none;
  color: #134d75;
}

.cardblog {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 .1rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background: #fff;
  height: 100%;
  /* background: linear-gradient(to right, #FFFFFF, #ECE9E6); */
}

.card__body_blog {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: .5rem;

}

.card__body h4 {
  font-size: 1.5rem;
  text-transform: capitalize;
}

/*Footer*/
.footer {
  background: #fff;
  background-image: url(../images/back.png);
  background-position: bottom;
  padding: 80px 0;
  padding-bottom: 0px;
  border-top: 1px solid #dee2e6 !important;
}

.footer-column:not(:first-child) {
  padding-top: 2rem;
}

.footerspace {
  display: grid;
  justify-content: center;
}

.footer-column {
  text-align: left;
}

.footer-column p {
  color: #666;

  text-align: justify;
  word-spacing: -1px;
}

.footer-column ul li a {
  color: #666;
  padding-left: 0;
}

.footer-column ul li a:hover {
  color: #134d75;
}

.footer-column ul li a:active {
  color: #134d75 !important;
}

span.footer-title {
  font-size: 14px;
  font-weight: 700;
  color: #666;
  text-transform: uppercase;
}

.phonerotation {
  rotate: 90deg;
}

ul {
  display: inline-block;
}

.footer-secondary {
  padding: 5px;
  background: #fff;
  border-top: 1px solid;
}

ul.social-buttons {
  margin-bottom: 0;
}

ul.social-buttons li a:active,
ul.social-buttons li a:focus,
ul.social-buttons li a:hover {
  background-color: orange;
  color: #fff;
}

ul.social-buttons li a {
  font-size: 20px;
  display: block;
  width: 40px;
  height: 40px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  color: #666;
  border-radius: 100%;
  outline: 0;
  background-color: background-grey;
}

footer .quick-links {
  font-size: 90%;
  line-height: 40px;
  margin-bottom: 0;
  text-transform: none;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.social-icon-footer {
  padding: 11px;
}

.copyright {
  color: #666;
}

.copyright a {
  text-decoration: none;
  font-weight: 600;
  color: rgb(36, 77, 117);
}

.copyright a:hover {
  color: #f6a21e;
  text-decoration: none;
  font-weight: 600;
}

/* .fa-ellipsis-h {
  color: #666;
  padding: 2rem 0;
} */
/*About Us Page*/

.aboutheader {
  color: #134d75;
  text-align: center;
}

.aboutuspagemidsection {
  padding: 80px 0;
  background: #fff;
}

.aboutuspagemidsection1 {
  padding-top: 0;
  padding-bottom: 80px;
  background-color: #fff;
}

.about_us_section {
  padding: 80px 0;
  background: #f1fafd !important
}

.about_us h2 {
  color: #f6a21e;
  font-weight: 700;
  text-align: center;
}

.about_us1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.about-us h2 {
  text-align: center;
  color: #134d75;
}

.about-us p {
  text-align: justify;
  color: #666;
}

.ourmission {
  padding: 80px 0;
}

.ourmission h2 {
  text-align: center;
  color: #134d75;
}

.connect_us_width {
  width: 100%;
}

.about_head h3 {
  color: #134d75;
}

.about_head p {
  text-align: justify;
  color: #666;
}

.vision_img {
  display: grid;
  justify-content: center;
}

.connectsection_top {
  margin-top: 80px !important;
  box-shadow: 4px 4px 10px #d1d9e6, -4px -4px 10px #f9f9f9;
  padding-bottom: 0 !important;
}

.connectabout {
  display: flex;
  align-items: center;
  height: 100%;
}

.connectaboutus {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
}

.connectsection {
  height: 100%;
  border-radius: 5px;
}

.aboutuscard {
  padding-top: 80px;
  padding-bottom: 0;
}

.contact_info1 {
  padding-top: 10px;
}

.contact_align1 {
  display: flex;
  align-items: center;
  height: 50px;
  /* padding-bottom: 3rem; */
}

.aboutustextgap {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 20px;
}

/*Contact Us Page*/
#contact-us {
  padding: 80px 0;
  background: #f1fafd !important;
}

.contact-us h1 {
  text-align: center;
  color: #134d75;
}

.contact-us p {
  text-align: center;
}

#contact_form {
  padding: 80px 0;
  background: #fff;
}

.contact_form {
  background: #fff;
  padding: 30px;
  box-shadow: 4px 4px 10px #d1d9e6, -4px -4px 10px #f9f9f9;
  height: 100%;
  border-radius: 5px 0px 0px 5px;
}

.contact_info {
  padding-top: 1.25rem;
}

.contact_form h4 {
  text-align: center;
  color: #134d75;
}

.contact_form p {
  text-align: center;
  color: #666;
}

.text-primary1 {
  color: #134d75;
  font-size: 20px;
}

.contact_align1 svg {
  fill: #134d75;
  font-size: 25px;
}

.contact_align svg {
  fill: #134d75;
  font-size: 26px;
}

.contact_phone {
  rotate: 90deg;
}

.contact_align {
  display: flex;
  align-items: center;
  height: 50px;
}

.social-buttons1 {
  display: block;
  color: #666;
  border-radius: 100%;
  outline: 0;
}

.social-icon-footer1 {
  padding: 10px;
}

.contact_detail {
  /* padding-left: 1rem; */
  margin-bottom: 0;
}

.contact_form1 {
  text-align: center;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  transition: 1.25s;
  overflow: hidden;
  position: relative;
  color: #fff;
  border-radius: 0px 5px 5px 0px;
  padding: 30px;
  box-shadow: 4px 4px 10px #d1d9e6, -4px -4px 10px #f9f9f9;
  background-image: linear-gradient(#134d75, #134e7598);
  z-index: 1;
}

.contact_form1::before {
  position: absolute;
  content: "";
  background: rgb(255 255 255 / 22%);
  left: -50px;
  bottom: 0;
  rotate: 180deg;
  opacity: 0.5;
  clip-path: polygon(20% 0, 83% 0, 94% 25%, 0 34%);
  width: 230px;
  height: 350px;
  z-index: 2;
}

.contact_form1::after {
  position: absolute;
  content: "";
  background: rgb(255 255 255 / 22%);
  right: 30px;
  opacity: 0.5;
  top: 185px;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  width: 200px;
  height: 200px;
  animation: fa-spin 8s infinite linear;
  z-index: 2;
}

.contact_form1>div::before {
  clip-path: polygon(100% 0, 0 60%, 100% 100%);
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  background: rgb(245 245 245 / 10%);
  right: 60px;
  animation: fa-spin 1s infinite linear;
  z-index: 2;
}

.contact_form1>div::after {
  position: absolute;
  content: "";
  width: 60px;
  height: 60px;
  background: rgb(255 255 255 / 22%);
  left: 70px;
  top: 40px;
  border-radius: 50%;
  opacity: 0.5;
  z-index: 2;
}

.contact_form1 h4 {
  text-align: center;
}

.contact_form1 p {
  text-align: center;
}

.aboutcontact {
  position: relative;
  z-index: 3;
}

/*Faqs Page*/
#faqs {
  padding: 80px 0;
  background: #fff;
}

.faqs h1 {
  text-align: center;
  color: #134d75;
}

.faqs p {
  text-align: center;
  color: #666;
}

.faqbtnshadow {
  padding: 0.8rem;
}

.faqbtnshadow:not(.collapsed) {
  box-shadow: none !important;
}

.faqsbody {
  padding: 0;
  color: #666;
  font-size: 15px;
}

.faqbtnshadow {
  background-color: #f1fafd !important;
  border-top: 1px solid rgba(0, 0, 0, 0.03);
}

.faqbtnshadow:focus {
  border-color: none;
  box-shadow: none;
  border-radius: 5px;
  background-color: #f1fafd !important;
  border-color: rgba(0, 0, 0, 0.03) !important;
}

.faqbtnshadow:not(.collapsed) h4 {
  color: #f6a21e !important;
}

.faqspacing {
  margin: auto;
}

.faq_accordian {
  margin-bottom: 30px;
}

.faq_accordian h4 {
  font-size: 18px;
  font-weight: 500;
  color: #134d75;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 448 512'%3E%3C!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cstyle%3Esvg{fill:%23f6a21e}%3C/style%3E%3Cpath d='M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z'/%3E%3C/svg%3E");
}

/*Community Guidline Page*/
#cgline {
  padding: 80px 0;
  background: #fff;
}

.cgline h1 {
  text-align: center;
  color: #134d75;
}

.cgline p {
  text-align: center;
  color: #666;
}

/*Privacy Policy */
#privacy_policy {
  padding: 80px 0;
  background: #fff;
}

.privacy_policy h1 {
  text-align: center;
  color: #134d75;
}

.privacy_policy p {
  text-align: center;
  color: #666;
}

.privacy_policy_text p {
  color: #666;
  word-spacing: 2px;
}


/*Blog PAge*/
.blogpage {
  padding: 80px 0;
  background: #f1fafd !important;
}

.blogpageheading {
  text-align: center;
}

.blogspacing {
  padding-bottom: 24px;
}

.blogpageheading h1 {
  color: #134d75;
}

.blogsection {
  padding: 80px 0;
  background: #fff !important;
}

.blogsection h4 {
  font-size: 20px;
}

.blogsection p {
  color: #666 !important;
}

.card-container {
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  margin: auto;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-radius: 25px;
  border: 1px solid #e1e1e1;
}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 40px;
}

.card-body h1 {
  font-size: 24px;
  margin: 8px 0;
  line-height: 35px;
}

.card-body p {
  margin: 8px 0 16px 0;
}

.blogbtn a {
  text-decoration: none;
  color: #666;

}

.blogbtn {
  color: #666;
  border: 1px solid #134d75;
  border-radius: 50px;
  padding: 12px 30px;
  color: #143d75;
  font-size: 16px;
}

.blogbtn:hover {
  color: #666;
  border: 1px solid #f6a21e;
  border-radius: 50px;
  padding: 12px 30px;
  color: #f6a21e;
  font-size: 16px;
}

.blogbtn:active {
  color: #666 !important;
  border: 1px solid #f6a21e;
  border-radius: 50px;
  padding: 12px 30px;
  color: #f6a21e;
  font-size: 16px;
}

.blogimgwidth {
  text-align: center;
  margin: auto;
}

.blogimgwidth img {
  width: 60%;
}

/*Silk Slider*/
.slick-prev {
  left: -60px !important;
}

.slick-next {
  right: -10px;
}

.slick-prev,
.slick-next {
  z-index: 1020;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

.slick-next,
.slick-prev {
  z-index: 1;
}

/*Join Orpect +*/
.joinop h1{
  text-align: center;
  color: #134d75;
}
.joinop p{
  text-align: center;
  margin: 0;
  color: #f6a21e;
}
.contact_form1123{
border-radius: 5px;
}
.joinopt{
color: #134d75;
}
.jorpli{
  color: #134d75;
  }
  .jorpli .oprlitxt{
    color: #212529;
    }
/* media Query */
@media(min-width:1400px) {
  .tab_lineheight1 {
    padding: 2rem;
  }
}

@media(max-width:1400px) and (min-width:1150px) {
  .tab_lineheight1 {
    padding: 1.2rem 2rem !important;
    height: 95%;
  }
}

@media (min-width:1200px) {
  .tab_lineheight {
    padding: 2rem;
  }
}

@media (min-width: 992px) {
  .nav-pills-custom .nav-link::before {
    content: '';
    display: block;
    border-top: 8px solid transparent;
    border-left: 10px solid white;
    border-bottom: 8px solid transparent;
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    opacity: 0;
  }

  .navlinkmobile {
    display: none;
  }

  #accordion {
    display: none;
  }
}

@media (min-width:567px) {
  .its-body-txt {
    padding-top: 0.5rem;
  }

  .mobile-responsive {
    display: none;
  }

  .feacturespaceimg {
    margin-top: 0rem;
  }
}

/*Max media query*/
@media (max-width:1250px) {
  .testimonial-author {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: -4.5rem;
    left: 0;
  }

  .testimonial-author img {
    border-radius: 50%;
    margin-bottom: 10px;
    width: 100px;
    height: 100px;
  }

  .slick-prev {
    left: 0px !important;
  }


  .slick-next {
    right: 0px !important;
  }

  .tab_lineheight1 {
    padding: 1.5rem;
    height: 95%;
  }
}

@media (max-width:1100px) {

  .testimonial-author img {
    border-radius: 50%;
    margin-bottom: 10px;
    width: 95px;
    height: 95px;
  }

  .testimonial-author1 img {
    border-radius: 50%;
    margin-bottom: 10px;
    width: 110px;
    height: 110px;
  }

  .blogcontent {
    line-height: 26px;
    color: #134d75;
    font-size: 16px;
    font-weight: 600;
  }
}

@media(max-width:1067px) {
  .its-body-txt {
    padding-top: 1rem;
  }

  .firstheader {
    padding-top: 1rem;
  }

}

@media(max-width:991px) {
  .logobar {
    width: 65%;
  }

  .jorpectbtn {
    padding-top: 10px !important;
  }

  .navlinkmobile1 {
    display: none;
  }

  .banner_responsive {
    width: 100%;
  }

  .navbar-toggle {
    display: block !important;
  }

  .footerspace {
    padding: 0;
  }

  .testimonial-author img {
    border-radius: 50%;
    margin-bottom: 10px;
    width: 90px;
    height: 90px;
  }

  .testimonial-author1 img {
    border-radius: 50%;
    margin-bottom: 10px;
    width: 90px;
    height: 90px;
  }

  .how_it_work_ul {
    padding: 0;
  }

  .tab_responsive {
    display: none;
  }

  .footer_height {
    line-height: 10px !important;
    font-size: 80% !important;
  }

  .slick-prev {
    left: -0px !important;
  }

  .slick-next {
    right: 0px !important;
  }

  .card__header {
    margin: unset;
  }

  .card__header img {
    height: 200px;
    margin: auto;
  }

  .dropdownicon::after {
    -webkit-text-fill-color: transparent;
    align-items: center;
    background: -webkit-linear-gradient(#255a80, #86d0db);
    -webkit-background-clip: text;
    border: none;
    bottom: 0;
    content: "\f107";
    display: flex;
    font-family: Font Awesome\ 5 Free;
    font-size: 16px;
    font-weight: 900;
    position: absolute;
    right: 3px;
    top: 0;
  }
}

@media (max-width:767px) {
  .banner-responsive {
    margin-top: 1.5rem;
  }

  .footerspace {
    padding: 0;
    display: grid;
    justify-content: start;
  }

  .check {
    height: 50px;
    margin-top: 10px;

  }

  .feactureimg {
    padding: 20px;
    display: none;
  }

  .card__header {
    margin: auto;
  }

  .card__header img {
    height: 200px;
    width: 100%;
  }

  .aboutcontactus {
    padding: 30px 10px !important;
  }
}


@media(max-width:576px) {

  .logobar {
    width: 60%;
  }

  .bannersection {
    padding: 30px;
    background-position: unset;
  }

  .banner-text h1 {
    font-size: 25px;
    text-align: left;
    line-height: 40px;
  }

  .midsection {
    padding: 30px;
  }

  .howitwork {
    padding: 30px;
  }

  .tabsection {
    padding: 30px;
  }

  .featuresection {
    padding: 30px;
  }

  .testimonial1 {
    padding: 30px;
  }

  #blog {
    padding: 30px;
  }

  .footer {
    padding: 30px;
    background-repeat: no-repeat;
  }

  .droplogin1 {
    left: -3rem;
    top: 2rem;
    right: auto !important;
  }

  .midhead p {
    text-align: justify;
    word-spacing: -1px;
    line-height: 1.7 !important;
  }

  .text-muted {
    text-align: justify;
  }

  .its-body-txt {
    padding-top: 0.5rem;
  }

  .mobile-responsive1 {
    display: none;
  }

  .featurespacing {
    margin-top: 0 !important;
  }

  .testimonial-author {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -4rem;
    width: 100%;
    left: 0;
  }

  .testimonial-author img {
    border-radius: 50%;
    margin-bottom: 10px;
    width: 85px;
    height: 85px;
  }

  .testimonial-bubble:before {
    bottom: -19px;
  }

  #about_demo {
    padding: 30px;
  }

  .about_us_section {
    padding: 30px;
    background: unset;
  }

  .parafeature {
    margin-left: 0rem;
    width: 100%;
  }

  .headfeature {
    font-weight: 700;
    font-size: 20px;
  }

  .box {
    padding: 0;
  }

  .its-body-txt h3 {
    font-size: 20px;

  }

  .its-body-txt li {
    font-size: 14px !important;
    text-align: left;
  }

  .check {
    margin-top: 10px;
    height: 30px;
  }

  .check1 {
    height: 30px;
  }

  .checkboximg {
    display: none;
  }

  .connect_us_width {
    width: 100%;
  }

  .aboutuspagemidsection {
    padding: 30px;
  }

  .ourmission {
    padding: 30px;
  }

  .connectsection_top {
    margin-top: 2rem !important;
  }

  .bannersection {
    padding: 30px;
    background-position: unset;
    background-size: cover;
  }

  .blog_spacing {
    padding: 1.5rem 1rem;
  }

  .about_head p {
    text-align: justify;
    color: #666;
    word-spacing: -1px;
  }

  #faqs {
    padding: 30px 20px;
  }

  #faqs h4 {
    font-size: 18px;
  }

  .faqs p {
    text-align: justify;
    color: #666;
    word-spacing: 0px;
  }

  #privacy_policy {
    padding: 30px;
  }

  .contact-us p {
    text-align: justify;
  }

  .privacy_policy_text h4 {
    font-size: 18px;
  }

  #cgline {
    padding: 30px;
  }

  .cgline p {
    text-align: justify;
    color: #666;
  }

  .privacy_policy p {
    text-align: justify;
    color: #666;
  }

  #contact-us {
    padding: 30px;
  }

  #contact_form {
    padding: 30px;
  }

  .blogcontent {
    font-size: 15px;
  }

  .blogcontent p {
    font-size: 14px;
  }

  .contact_detail {
    /* padding-left: 1rem; */
    word-wrap: break-word;
    /* line-break: unset; */
    text-align: left !important;
  }

  .contact_align1 {
    display: flex;
    align-items: center;
    padding-bottom: 4rem;
  }

  .contact_align {
    display: flex;
    align-items: center;
    padding-bottom: 4rem;
  }

  .social-buttons1 {
    display: block;
    color: #666;
    border-radius: 100%;
    outline: 0;
    font-size: 14px;
  }

  .social-icon-footer1 {
    padding: 5px 7px;
  }

  .social-icon-footer1:hover {
    border-radius: 50%;
    padding: 5px 7px;
    /* width: 40px;
  height: 40px; */
  }

  .left_form {
    padding-left: 10px;
  }

  .contact_form {
    padding: 30px 10px;
  }

  .contact_form1 {
    padding: 30px 10px;
  }

  .mobile-responsive {
    text-align: center;
  }

  .about-us h2 {
    text-align: center;
    padding-top: 20px;
  }

  .about_us {
    padding: 100px 0;
  }

  .about-us p {
    text-align: justify;
    word-spacing: -1px;
  }

  .how-it-works-mobile {
    text-align: left;
  }

  .blogpage {
    padding: 30px;
  }

  .blogsection {
    padding: 30px;
  }

  .blogimgwidth {
    width: 100%;
    margin: auto;
  }

  .slick-prev {
    left: -10px !important;
  }

  .slick-next {
    right: 0px !important;
  }


}

@media (max-width:420px) {
  .contact_align svg {
    font-size: 36px;
  }

  .contact_align1 svg {
    fill: #134d75;
    font-size: 32px;
  }

  .contact_align3 svg {
    font-size: 26px !important;
  }

  .contact_align4 svg {
    font-size: 30px !important;
  }
}

@media (max-width:390px) {
  .contact_align1 svg {
    fill: #134d75;
    font-size: 36px;
  }

  .contact_align3 svg {
    font-size: 30px !important;
  }

  .contact_align4 svg {
    font-size: 34px !important;
  }
}

@media (max-width:360px) {
  .contact_align svg {
    fill: #134d75;
    font-size: 50px;
  }

  .contact_align1 svg {
    fill: #134d75;
    font-size: 40px;
  }

  .contact_align3 svg {
    font-size: 35px !important;
  }

  .contact_align4 svg {
    font-size: 40px !important;
  }
}

@media(max-width:370px) {
  .testimonial-author {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -2.5rem;
    width: 100%;
    left: 0;
  }

  .testimonial-author img {
    border-radius: 50%;
    margin-bottom: 10px;
    width: 70px;
    height: 70px;
  }


  .testimonial-bubble:before {
    bottom: -19px;
  }

  .fa-user:before {
    font-size: 18px;
  }

  .its-body-txt h3 {
    font-size: 16px;
  }

  .its-body-txt li {
    font-size: 14px !important;
    text-align: left;
  }

}

@media(max-width:340px) {
  .logobar {
    width: 50%;
  }

  .testimonial-bubble {
    position: relative;
    background-color: #134d75;
    border-radius: 20px;
    padding: 15px 10px 10px 10px;
    text-align: center;
    box-shadow: 0 0 10px 0 #fff;
    margin: 15px;
    color: #e3ffe7;
  }

  .testimonial-bubble1 {
    position: relative;
    background-color: #134d75;
    border-radius: 20px;
    padding: 15px 10px 10px 10px;
    text-align: center;
    box-shadow: 0 0 10px 0 #fff;
    margin: 15px;
    color: #e3ffe7;
  }

  .testimonial-author img {
    border-radius: 50%;
    margin-bottom: 10px;
    width: 60px;
    height: 60px;
  }

  .testimonial-author1 img {
    border-radius: 50%;
    margin-bottom: 10px;
    width: 60px;
    height: 60px;
  }
}