/* Modal Overlay */
.modal.show {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

/* Modal Content */
.modal-content {
  max-width: 450px;
  width: 100%;
}

/* Modal Header */
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: none;
}

/* Modal Body */
.modal-body {
  padding: 20px;
}

/* Crop Container */
.crop-container {
  position: relative;
  width: 100%;
  height: 300px;
  margin-bottom: 20px;
  margin-top: 20px;
}

/* Zoom Range Input */
.zoom-range {
  width: 100%;
}

/* Controls */
.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Save Button */
.save-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Reset Button */
.controls button {
  padding: 10px 20px;
  background-color: #f8f9fa;
}

.modal.show .modal-dialog{
  width: 500px;
}

@media (max-width:576px){

  .modal-content{
    width: 300px !important;
    margin: auto;
  }

}